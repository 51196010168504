import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Form, Field } from 'react-final-form'
import styles from './LoginScreen.module.css'
import logo from '../Resources/logo.png'

const defaultValidate = values => {
  const errors = {}
  if (!values.username) errors.username = "Required"
  if (!values.password) errors.password = "Required"
  return errors
}

const LoginScreen = (props) => {

  const {
    usernamePlaceholder,
    usernameIsEmail,
    onSubmit,
    validate,
  } = props

  const env = get(window, 'env', {})
  let version
  if (env.RELEASE) {
    version = `Release: ${env.RELEASE}`
  } else if (env.BUILD) {
    version = `Build: ${env.BUILD}`
  } else {
    version = `Local Development`
  }

  return (

    <div className={styles.wrapper}>
      
      <div className={styles.formPanel}>
        
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, pristine, submitError }) => (

            <form
              onSubmit={handleSubmit}
              className={styles.form}
            >

              <div className={styles.logoContainer}>
                <img src={logo} width={300} alt="GV App Logo" />
              </div>

              <div className={styles.subheading}>
                Log in with your GV App account
              </div>

              {submitError && (
                <div className={styles.invalid}>
                  {submitError}
                </div>
              )}

              <div>
                
                <label htmlFor="username">
                  {usernameIsEmail ? 'Email Address' : 'Username'}
                </label>

                <Field
                  id="username"
                  name="username"
                  component="input"
                  placeholder={usernamePlaceholder}
                  autoComplete="off"
                  disabled={submitting}
                  onKeyPress={e => e.which === 13 && handleSubmit()}
                />

              </div>

              <div>

                <label htmlFor="password">
                  {'Password'}
                </label>

                <Field
                  id="password"
                  name="password"
                  component="input"
                  type="password"
                  placeholder="Enter your password"
                  autoComplete="current-password"
                  disabled={submitting}
                  onKeyPress={e => e.which === 13 && handleSubmit()}
                />

              </div>

              <button
                type="submit"
                disabled={pristine || submitting}
              >
                {submitting ? 'Authenticating' : 'Sign In'}
              </button>

            </form>

          )}
        />

        <div className={styles.version}>
          {version}
        </div>

      </div>
      
    </div>

  )
}

LoginScreen.propTypes = {
  usernameIsEmail: PropTypes.bool,
  usernamePlaceholder: PropTypes.string,
  validate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

LoginScreen.defaultProps = {
  usernameIsEmail: false,
  usernamePlaceholder: 'johnsmith',
  validate: defaultValidate,
}

export default LoginScreen